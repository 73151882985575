/* You can add global styles to this file, and also import other style files */
@import 'https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'
@import 'scss/bsVidoboardVariables'
@import '~bootstrap/scss/bootstrap'
@import "~@angular/material/prebuilt-themes/indigo-pink.css"
@import '~@vime/core/themes/default.css'

body
  font-size: 14px
  color: #3c3c3b

h1, h2, h3, h4
  font-family: 'Signika', sans-serif !important

h3.project-detail-title
  font-family: 'Source Code Pro'
  font-size: 10px
  font-weight: normal
  text-transform: uppercase

.hidden
  display: none

// Container
.container-fluid
  padding-right: 80px
  padding-left: 80px


// FONTS
.text-small-font
  font-size: 10px

.text-bold
  font-weight: bold

.text-medium-font
  font-size: 16px

.text-light-grey
  color: #a8a8a7

.text-dark-grey
  color: #3c3c3b

// general background colors

.bg-light-green
  background-color: #6ab131

.bg-light-grey
  background-color: #f4f4f4

// general font colors
.text-vvv-green-main
  color: #6ab131


// Buttons
.btn-primary.rounded-pill
  text-transform: uppercase
  letter-spacing: 0.2em

.btn-primary.rounded-pill:hover
  background-color: $button-hover-color

.btn-primary.background-white
  background-color: #ffffff

.btn-primary:disabled
  color: #4a5568
  opacity: 1

.btn-primary.background-white:disabled
  background-color: #cccccc

// Forms
::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder
  color: #b5b5b7 !important
  opacity: 0.9

option
  color: #616064

textarea
  border: none
  overflow: auto
  outline: none

  -webkit-box-shadow: none
  -moz-box-shadow: none
  box-shadow: none

  resize: none
/*remove the resize handle on the bottom right*/


.vvv-input-big
  @extend .rounded-pill
  @extend .pr-3
  @extend .pl-3
  @extend .pt-3
  @extend .pb-3
// error messages
.vvv-input-big-error
  @extend .text-danger
  @extend .pl-3
  @extend .mt-2

.vvv-select-big
  @extend .rounded-pill

.vvv-textarea-big
  @extend .rounded-pill
  @extend .pr-1
  @extend .pl-1
  @extend .pt-1
  @extend .pb-1

.vvv-input-medium
  @extend .rounded-pill
  @extend .pr-3
  @extend .pl-3
  @extend .pt-1
  @extend .pb-1
  height: 36px !important
  min-width: 110px

// INPUT SMALL

.vvv-input-small
  @extend .rounded-pill
  @extend .pr-3
  @extend .pl-3
  @extend .pt-1
  @extend .pb-1
  height: 25px !important

// error messages
.vvv-input-small-error
  @extend .text-danger
  @extend .pl-2
  @extend .mt-2

// buttons

.vvv-button-rounded
  @extend .rounded-pill

.vvv-button-small
  height: 25px
  @extend .rounded-pill
  @extend .pr-3
  @extend .pl-3

.vvv-button-normal
  @extend .vvv-button-rounded
  @extend .pl-3
  @extend .pr-3
  @extend .pt-1
  @extend .pb-1
  border: 2px #7AAF47 solid !important
  white-space: nowrap
  transition: 0.5s

.vvv-button-white
  @extend .vvv-button-normal
  background-color: #fff
  color: #7AAF47

.vvv-button-white-small
  @extend .vvv-button-small
  background-color: #fff
  color: #7AAF47
  border: 1px solid
  white-space: nowrap

.vvv-button-small-usermenu
  width: 120px
  font-size: 10px

.vvv-button-white:hover
  background-color: #7AAF47 !important
  color: #fff
  font-weight: 600

button.comment-button
  height: 24px
  width: 100%
  text-align: center
  text-transform: uppercase
  margin: 17px 0 0
  border-radius: 12px
  font-size: 10px
  font-weight: 600
  border: solid 1.5px #7aaf47
  background-color: #fff
  color: #7aaf47

.comment-done
  opacity: 0.4

.username-and-time
  color: #a8a8a7
  margin-bottom: 3px

.text-align-center
  text-align: center

.text-color-white
  color: white

// type ahead
.suggestions, .suggestions-history, .vvv-input-textfield
  border-radius: 50rem !important

// LOGIN

.login-mt-welcome
  margin-top: 5rem !important

.login-logo
  max-width: 250px !important

// Logo
.logo-small
  width: 17px
  height: 15px

// Header

.header-logged-in
  height: 40px

.navbar
  padding: 0.1rem 0

.navbar a, .navbar a:hover
  color: #ffffff

.row-header-title
  height: 180px

.row-header-title-small
  height: 90px

.header-title-big
  font-size: 30px
  font-weight: 400 !important
  font-family: 'Signika', sans-serif

.header-navigation-username-menu
  font-weight: bold
  text-transform: uppercase
  font-size: 10px

.vido-user-menu-wrapper
  padding: 0
  margin: 0

  li
    list-style-type: none


.vido-user-menu
  width: 375px

  a, a:hover
    color: #7AAF47

.vido-user-menu-dropdown-menu
  margin-left: -247px !important

.vido-user-menu-divider-hr
  border: dashed #a8a8a7 1px

.header-title-margin
  margin-top: 40px

// Breadcrumbs

// Modals

.modal-form-label
  font-size: 20px

.close
  opacity: 1

// ## GREEN MODALS

.modal-green
  .modal-content
    background-color: #7AAF47
    color: #fff

  .modal-title
    font-family: 'Signika', sans-serif
    font-size: 30px

  .modal-form-label, .form-text, .close
    color: #fff

  hr
    border-color: #fff

.modal-xxl
  width: 80% !important

.modal-dialog .modal-header
  border: 0

.modal-dialog:not(.modal-lg)
  max-width: 100% !important

  .modal-content
    width: 90% !important
    max-width: 90% !important
    margin: 0 auto !important

.modal .modal-dark
  background-color: #343434 !important
  opacity: 1

.modal-light .modal-content
  background: transparent !important
  border: 0

.white-background
  background: white

// icon

.icon-rounded-circle
  width: 40px
  height: 40px
  border-radius: 50%
  background-color: #7AAF47
  line-height: 40px

.icon-drag-drop
  width: 15px
  height: 15px

.icon-shevron-up
  width: 10px
  height: 10px

.icon-shevron-down
  width: 10px
  height: 10px
  transform: rotate(180deg)

.dashboard-icon
  width: 30px
  height: 30px

.icon-expand
  transform: rotate(-90deg)
  transition-duration: 120ms

  .assets-open &
    transform: rotate(0)

.icon-small
  width: 20px
  height: 20px

// Assets

// card

.card:not(.card-dotted)
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
  border: 0

.card-dotted
  border: 1px dashed #3c3c3b

.card
  .card-footer
    background-color: #fff

// border

.border-dashed-y
  border-top: 1px dashed #a8a8a7
  border-bottom: 1px dashed #a8a8a7

.border-dashed-top
  border-top: 1px dashed #a8a8a7

.border-dashed-bottom
  border-bottom: 1px dashed #a8a8a7

// avatar
.col-avatar
  -ms-flex: 0 0 85px
  flex: 0 0 85px

// storyboard

.storyboard.side-bar-title
  background-color: #7aaf47 !important
  color: white
  font-weight: 800
  text-transform: uppercase

.sidebar-height
  min-height: 475px
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

// datepicker

bs-datepicker-container
  z-index: 99999
// mouse options

.mouse-pointer
  cursor: pointer

.mouse-drag
  cursor: grab

// drag & drop

/* in-flight clone */
.gu-mirror
  position: fixed !important
  margin: 0 !important
  z-index: 9999 !important
  opacity: 0.8
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"
  filter: alpha(opacity=80)
  pointer-events: none

/* high-performance display:none; helper */
.gu-hide
  left: -9999px !important

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable
  -webkit-user-select: none !important
  -moz-user-select: none !important
  -ms-user-select: none !important
  user-select: none !important

/* added to the source element while its mirror is dragged */
.gu-transit
  opacity: 0.2
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)"
  filter: alpha(opacity=20)

/*toastr*/

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.toast-top-center
  top: 0
  right: 0
  width: 100%

.toast-bottom-center
  bottom: 0
  right: 0
  width: 100%

.toast-top-full-width
  top: 0
  right: 0
  width: 100%

.toast-bottom-full-width
  bottom: 0
  right: 0
  width: 100%

.toast-top-left
  top: 12px
  left: 12px

.toast-top-right
  top: 12px
  right: 12px

.toast-bottom-right
  right: 12px
  bottom: 12px

.toast-bottom-left
  bottom: 12px
  left: 12px

.scrollable-y
  overflow-y: scroll
  overflow-x: hidden

/* toast styles */
.toast-title
  font-weight: bold

.toast-message
  word-wrap: break-word

.toast-message a,
.toast-message label
  color: #FFFFFF

.toast-message a:hover
  color: #CCCCCC
  text-decoration: none

.toast-close-button
  position: relative
  right: -0.3em
  top: -0.3em
  float: right
  font-size: 20px
  font-weight: bold
  color: #FFFFFF
  text-shadow: 0 1px 0 #ffffff
/* opacity: 0.8 */

.toast-close-button:hover,
.toast-close-button:focus
  color: #000000
  text-decoration: none
  cursor: pointer
  opacity: 0.4

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button
  padding: 0
  cursor: pointer
  background: transparent
  border: 0

.toast-container
  pointer-events: none
  position: fixed
  z-index: 999999

.toast-container *
  box-sizing: border-box

.toast-container .ngx-toastr
  position: relative
  overflow: hidden
  margin: 0 0 6px
  padding: 15px 15px 15px 50px
  width: 300px
  border-radius: 3px 3px 3px 3px
  background-position: 15px center
  background-repeat: no-repeat
  background-size: 24px
  box-shadow: 0 0 12px #999999
  color: #FFFFFF

.toast-container .ngx-toastr:hover
  box-shadow: 0 0 12px #000000
  opacity: 1
  cursor: pointer

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info
  background-image: url("data:image/svg+xmlbase64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+")

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error
  background-image: url("data:image/svg+xmlbase64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+")

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success
  background-image: url("data:image/svg+xmlbase64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==")

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning
  background-image: url("data:image/svg+xmlbase64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==")

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr
  width: 300px
  margin-left: auto
  margin-right: auto

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr
  width: 96%
  margin-left: auto
  margin-right: auto

.ngx-toastr
  background-color: #030303
  pointer-events: auto

.toast-success
  background-color: #51A351

.toast-error
  background-color: #BD362F

.toast-info
  background-color: #2F96B4

.toast-warning
  background-color: #F89406

.toast-progress
  position: absolute
  left: 0
  bottom: 0
  height: 4px
  background-color: #000000
  opacity: 0.4

/* Responsive Design */
@media all and (max-width: 240px)
  .toast-container .ngx-toastr.div
    padding: 8px 8px 8px 50px
    width: 11em

  .toast-container .toast-close-button
    right: -0.2em
    top: -0.2em


@media all and (min-width: 241px) and (max-width: 480px)
  .toast-container .ngx-toastr.div
    padding: 8px 8px 8px 50px
    width: 18em

  .toast-container .toast-close-button
    right: -0.2em
    top: -0.2em


@media all and (min-width: 481px) and (max-width: 768px)
  .toast-container .ngx-toastr.div
    padding: 15px 15px 15px 50px
    width: 25em

.sub-navigation
  float: left
  display: inline
  margin: 0
  padding: 0

.sub-navigation li
  list-style-type: none
  margin: 0
  padding: 0

.cdk-drop-list-dragging .cdk-drag
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.cdk-drag-animating
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1)

.drag-placeholder
  width: 500px
  max-width: 100%
  border: solid 1px #ccc
  min-height: 60px
  display: block
  background: white
  border-radius: 4px
  overflow: hidden

.hide-frames
  display: none

// Table
.table thead th
  font-weight: normal

// Datepicker
.theme-green .bs-datepicker-head, .theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after
  background: #6ab131 !important

.theme-green .bs-datepicker-body table td.week span
  color: #6ab131 !important

// Autocomplete
.form-group .ng-autocomplete
  width: 100%
  display: table
  margin: 0 auto

  box-shadow: none !important
  background: transparent

  .autocomplete-container
    box-shadow: none
    height: 35px
    border: 1px solid #ced4da
    border-radius: 50rem !important
    background-color: #fff

    .input-container input
      background-color: transparent
      @include font-size($input-font-size)
      height: 35px
      line-height: 35px


// upload projectAssetTabs in Settings

.projectAssetTab-upload-frame
  width: 150px
  height: 150px
  border: 1px solid #fff

  input
    display: none

.input-dimension
  max-width: 80%

.comment-inline-popup
  width: 100% !important


.projectAssetSettings
  background-color: #efefef
