$theme-colors:           (
                           "primary": rgb(122, 175, 71),
                           "secondary": #02bceb,
                           "hover": rgb(57, 110, 33)
                         );

$color-darkGreen:        rgb(57, 110, 33);

// font
$font-size-base:         14;
$body-color:             #3c3c3b;

$font-family-sans-serif: "Source Sans Pro", sans-serif !important;
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

// buttons
$button-hover-color:     rgb(57, 110, 33);

// Forms
$form-grid-gutter-width: 15px !default;

// Modals
$modal-inner-padding:    1.5rem !default;
$modal-header-padding-x: 1.5rem !default;

// Close
$close-font-size:        $font-size-base * 2.5 !default;
$close-font-weight:      300;
